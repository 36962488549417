import React from 'react';
import logo from '../assets/logo.png';
import bg from '../assets/bg.jpg';
import footerLogo from '../assets/footer-logo.png';
import '../App.css';

function Contacts() {
  return (
    <div className="App">
      <div id="token"></div>
      <div id="message"></div>
      <div id="error"></div>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="header-img-box">
        <img src={bg} className="header-img" alt="bg" />
      </div>
      <main>
        <div className="container">
          <h2>Contact</h2>
          <h3>Fripp Island Property Owners Association</h3>
          <div className="panel-body">                                                        
            <div className="grey-text">
              <p>If you still have questions for the <strong>Fripp Island Property Owners Association</strong>, email 
              <a href="mailto:contact@frippislandliving.com">contact@frippislandliving.com</a> or use the numbers below.</p>
            </div>                                                                                                                                                                                                                                                                                                                                                                                         
            <ul className="imp-inf-list">
              <li>
                  Administration Office: <strong>(843) 838-4155</strong>
              </li>
              <li>
                  Administration Fax: <strong>(843) 838-9072</strong>
              </li>
              <li>
                  Administration Toll Free: <strong>(888) 838-6501</strong>
              </li>
              <li>
                  Security Office: <strong>(843) 838-2334</strong>
              </li>
              <li>
                  Security Fax: <strong>(843) 838-2101</strong>
              </li>
            </ul>                                                                                                                                                                                                                                                                                                     
            <div className="grey-text">
              <p>If you still have questions about the <strong>Fripp Island Resort</strong>, 
              you can call them at <strong>(877) 761-0223</strong> or email 
              <a href="mailto:guestservices@frippislandresort.com">guestservices@frippislandresort.com</a>.
              </p>
            </div>                                                                                                                                                                                                                                                                                                                                                 
          </div>
        </div>
      </main>
      <footer>
        <img src={footerLogo} className="footer-logo" alt="logo" />
        <div className="footer-details">
          <p className="address">Fripp Island Property Owners Association <br/><br/>
            225 Tarpon Boulevard, Fripp Island, SC 29920 <br/>
            Office: <a href="/">(843) 838-4155</a> <br/>
             Security <a href="/">(843) 838-2334</a>
          </p>
          <p className="small-info">Use of this website is subject to our <a href="/">Terms &amp;
                    Conditions</a> and <a href="/">Privacy Policy</a></p>
        </div>
      </footer>
    </div>
  );
}

export default Contacts;
