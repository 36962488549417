import * as React from "react";
import { PostList, PostShow, PostCreate } from "./posts";
import { Admin, Resource } from "react-admin";
import {
    FirebaseRealTimeSaga,
    FirebaseDataProvider,
    FirebaseAuthProvider,
    RAFirebaseOptions
} from "react-admin-firebase";

import {
    Layout as DefaultLayout,
    Loading,
    Login,
    Logout,
    LoginForm,
    NotFound,
    // @ts-ignore
} from 'ra-ui-materialui';

import { withStyles } from '@material-ui/core/styles';

import img from '../static/img/2.jpg'

const config = require("../FIREBASE_CONFIG.js").firebaseConfig;

const options: RAFirebaseOptions = {
    logging: true
}

const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);
const firebaseRealtime = FirebaseRealTimeSaga(dataProvider, options);

const styles = {
    login: {
        main: {
            backgroundImage: `url(${img})!important`
        }
    }
}


class AdminPage extends React.Component {
    render() {
        return (
            <Admin
                appLayout={withStyles(styles.login)(DefaultLayout)}
                catchAll={withStyles(styles.login)(NotFound)}
                loading={withStyles(styles.login)(Loading)}
                loginPage={withStyles(styles.login)(Login)}
                logoutButton={Logout}
                customSagas={[firebaseRealtime]}
                dataProvider={dataProvider}
                authProvider={authProvider}
            >
                <Resource
                    name="messages"
                    list={PostList}
                    show={PostShow}
                    create={PostCreate}
                    edit={null}
                />
            </Admin>
        );
    }
}

export default AdminPage;