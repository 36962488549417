import React, { Component, useEffect } from "react";

import AdminPage from "./admin/Admin";
import Messages from "./messages/Messages";

import { notificationsService } from './notifications.service'

import * as ROUTES from "./constants/routes.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// class DynamicImport extends Component {
//   state = {
//     component: null
//   }
//   componentWillMount () {
//     this.props.load()
//       .then((component) => {
//         this.setState(() => ({
//           component: component.default 
//             ? component.default 
//             : component
//         }))
//       })
//   }
//   render() {
//     return this.props.children(this.state.component)
//   }
// }

// const Messages = (props) => (
//   <DynamicImport load={() => import('./messages/Messages')}>
//     {(Component) => Component === null
//       ? <p>Loading</p>
//       : <Component {...props} />}
//   </DynamicImport>
// )

// const AdminPage = (props) => (
//   <DynamicImport load={() => import('./admin/Admin')}>
//     {(Component) => Component === null
//       ? <p>Loading</p>
//       : <Component {...props} />}
//   </DynamicImport>
// )

function App() {
  useEffect(() => {
    notificationsService.init()
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.MAIN} component={Messages} />
        <Route exact path={ROUTES.ADMIN} component={AdminPage} />
      </Switch>
    </Router>
  );
}

export default App;
