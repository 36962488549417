import React, { useState, useEffect } from "react"
import firebase from '../firebase'
import Contacts from '../contacts/Contacts'
import './style.css'

const db = firebase.firestore()

type TypeTime = {
  seconds: number;
}

type TypeMessage = {
  id: string;
  body: string;
  createdate: TypeTime;
  createdby: number;
  lastupdate: TypeTime;
  title: string;
  updateby: string;
  onClick: () => void;
}

function Message(props: TypeMessage) {
  const { body, title, onClick } = props;
  return (
    <div className="message">
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <button onClick={onClick}>OK</button>
    </div>
  )
}

function Messages() {
  const [messages, setMessages] = useState<{[key: string]: TypeMessage}>({})

  useEffect(() => {
    db.collection("messages")
      .onSnapshot(function(querySnapshot) {
          const newMessages: any = {};
          querySnapshot.forEach((doc: any) => {
             const data = doc.data();
             data.id = doc.id;
             newMessages[data.id] = data
          });

          setMessages(newMessages)
      })
  }, [])

  const messageList = Object.values(messages).sort((a, b) => a.createdate.seconds - b.createdate.seconds)
  const lastMessage = messageList[messageList.length - 1];
  const remove = (id: any) => {
    setMessages({})
  }

  return (
    <div className="content">
      <div>
        {/* {messageList.map(x => (<Message key={x.id} {...x} onClick={() => remove(x.id)} />))} */}
      </div>
      <Contacts />
    </div>
  )
}

export default Messages