import * as React from "react";
import './style.css'

import {
  Datagrid,
  List,
  Show,
  Create,
  Filter,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ShowButton,
  DeleteButton,
} from "react-admin";


const PostFilter = (props: any) =>
  <Filter {...props}>
    <TextInput label="Search" source="title" alwaysOn />
  </Filter>

export const PostList = (props: any) =>
  <List {...props} filters={<PostFilter />}>
    <Datagrid>
      <TextField source="title" label="Massages"/>
      <ShowButton label="" />
      <DeleteButton label="" resource="list" undoable={false}/>
    </Datagrid>
  </List>

export const PostShow = (props: any) =>
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Message ID"/>
      <TextField source="title" label="Message text"/>
    </SimpleShowLayout>
  </Show>

export const PostCreate = (props: any) =>
  <Create {...props}>
    <SimpleForm redirect="list" class="change-button">
      <TextInput source="title" label="Message Text"/>
    </SimpleForm>
  </Create>

