import firebase, { fbApp } from "./firebase";
import Logo from './assets/logo40.png';

class NotificationService {
  init = async () => {
    try {
      if (!navigator.serviceWorker || process.env.NODE_ENV === "test") {
        return;
      }

      require("firebase/messaging");

      const messaging = firebase.messaging(fbApp);
      messaging.usePublicVapidKey(
        "BIEVD4iWbSXpt0_051FDsghn_YFVZdYgzrMBZfbUf4SmCWXDYqq7BxXKjHcWw3Lnlpl4d4tWiTlw_hKlc_mhAyI"
      );

      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js"
      );
      messaging.useServiceWorker(registration);
      messaging.onMessage(message => {
        registration.showNotification(message.notification.title, {
          body: message.notification.body || '',
          icon: Logo
        })
      })

      const token = await messaging.getToken();
      this.sendTokenToServer(token);

      messaging.onTokenRefresh(() =>
        messaging.getToken().then(this.sendTokenToServer)
      );
    } catch (e) {
      console.error(e);
    }
  };

  sendTokenToServer = (FCMToken: string) => {
    fetch("https://us-central1-fripp-dev.cloudfunctions.net/save_token", {
      method: "post",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ token: FCMToken })
    }).catch(e => {
      console.error(e)
    });
  };
}

export const notificationsService = new NotificationService();
